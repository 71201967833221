
import Vue from 'vue'
import Component from 'vue-class-component'
import EligibilityCriterionResult from '@/data/entity/programs/eligibility-criterion-result.entity'
import { Prop } from 'vue-property-decorator'
import ProgramId from '@/data/entity/programs/program-id.entity'

type Explanations = {
  [key in ProgramId]: any
}

const proofOfFunds = require('./common/proof-of-funds.partial')

const explanations: Explanations = {
  cec: {
    workExpAmount: require('./programs/cec/cec-work-exp-amount.partial'),
    workExpType: require('./programs/cec/cec-work-exp-type.partial'),
    language: require('./programs/cec/cec-language.partial'),
  },
  fsw: {
    language: require('./programs/fsw/fsw-language.partial'),
    education: require('./programs/fsw/fsw-education.partial'),
    workExp: require('./programs/fsw/fsw-work-exp.partial'),
    fswSelectionFactors: require('./programs/fsw/fsw-selection-factors.partial'),
    proofOfFunds,
  },
  fst: {
    workExpAmount: require('./programs/fst/fst-work-exp-amount.partial'),
    jobOfferOrCert: require('./programs/fst/fst-job-offer-or-cert.partial'),
    language: require('./programs/fst/fst-language.partial'),
    proofOfFunds,
  },
  pnp: {},
}

@Component
export default class EligibilityCriterionDialog extends Vue {
  @Prop({ type: String })
  programId!: ProgramId
  @Prop()
  result!: EligibilityCriterionResult

  get title(): string {
    return this.$ts(`eligibility.criteria.${this.result.criterion.title}`)
  }

  get descriptionHtml(): string | null {
    return explanations[this.programId][this.result.criterion.title]
  }
}
