
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import { ProfileTypes } from '@/store/modules/profile.module'
import SelectOption from '@/common/interfaces/select-option'
import { Watch } from 'vue-property-decorator'

import { LanguageTest, ALL_LANGUAGE_TESTS } from '@/data/entity/profile/language-test'
import FormattingUtils from '@/common/util/formatting-utils'
import { LanguageTestType, LanguageTestScores } from 'shared-entities'
import { LanguageSkillScore } from '@/data/entity/profile/language-skill-score'
import PointCategory from '@/data/point-calculator/point-category'

import CategoryPointsLabel from '../../common/CategoryPointsLabel.vue'
import LanguageSkillsInput from '../../common/LanguageSkillsInput.vue'
import LanguageFormBExplanation from './LanguageFormBExplanation.vue'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

@Component({
  components: {
    CategoryPointsLabel,
    LanguageSkillsInput,
  },
})
export default class LanguagesFormB extends Vue {
  languageTests = ALL_LANGUAGE_TESTS

  get languageTest(): LanguageTest | null {
    return ProfileModuleUtils.getSpouseField('firstLanguageTest')
  }

  get languageTestType(): LanguageTestType | null {
    const test = this.languageTest
    if (test) {
      return test.type
    }
    return null
  }

  get languageScores(): LanguageTestScores | null {
    return store.getters[ProfileTypes.getters.spouseLanguageScores]
  }

  get category(): PointCategory {
    return store.state.profile.points.categoryB.language
  }

  onLanguageTestChanged(type: LanguageTestType | null) {
    ProfileModuleUtils.setSpouseField('firstLangTestType', type)
  }

  onInput(score: LanguageSkillScore) {
    store.commit(ProfileTypes.mutations.setSpouseLanguageSkillScore, score)
  }

  showHelp() {
    this.$dialog.show({
      component: LanguageFormBExplanation,
      large: true,
    })
  }
}
