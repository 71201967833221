
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'
import {
  FIRST_LANGUAGE_SCORES,
  LanguageProficiencyScore,
  SECOND_LANGUAGE_SCORES,
} from '@/data/point-calculator/categories/core/language-point-calculator'

interface LanguageScore {
  startCLB: number
  endCLB: number
  pointsWithSpouse: number
  pointsWithoutSpouse: number
}

@Component({
  components: {
    ExplanationLink,
  },
  i18n: {
    messages: {
      en: {
        local: {
          title: 'Official languages proficiency',
          subtitle: 'Maximum points for each ability (reading, writing, speaking and listening):',
          first: {
            title: 'First official language',
            withSpouse: '32 with a spouse or common-law partner',
            withoutSpouse: '34 without a spouse or common-law partner',
          },
          second: {
            title: 'Second official language',
            withSpouse:
              '6 with a spouse or common-law partner (up to a combined maximum of 22 points)',
            withoutSpouse:
              '6 without a spouse or common-law partner (up to a combined maximum of 24 points)',
          },
          explanationText: 'how your test scores are converted into CLB equivalents',
        },
      },
    },
  },
})
export default class LanguageFormExplanation extends Vue {
  firstLanguageScores: LanguageScore[] = []
  secondLanguageScores: LanguageScore[] = []

  created() {
    this.generateTableValues(this.firstLanguageScores, FIRST_LANGUAGE_SCORES)
    this.generateTableValues(this.secondLanguageScores, SECOND_LANGUAGE_SCORES)
  }

  private generateTableValues(
    scores: LanguageScore[],
    proficiencyScores: LanguageProficiencyScore[]
  ) {
    let previousScore = 0
    let prevPointsWithSpouse = 0
    let prevPointsWithoutSpouse = 0
    for (let i = 0; i < proficiencyScores.length; i++) {
      const score = proficiencyScores[i]
      scores.push({
        startCLB: previousScore,
        endCLB: score.minCLB - 1,
        pointsWithSpouse: prevPointsWithSpouse,
        pointsWithoutSpouse: prevPointsWithoutSpouse,
      })

      previousScore = score.minCLB
      prevPointsWithSpouse = score.pointsWithSpouse
      prevPointsWithoutSpouse = score.pointsWithoutSpouse
    }

    scores.push({
      startCLB: previousScore,
      endCLB: Infinity,
      pointsWithSpouse: prevPointsWithSpouse,
      pointsWithoutSpouse: prevPointsWithoutSpouse,
    })
  }
}
