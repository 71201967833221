
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

/**
 * Renders a help text with a question mark button, which emits
 * a `show-help` event when clicked.
 */
@Component
export default class PointCalculatorHelpLabel extends Vue {
  /**
   * Align the label to the left if true.
   */
  @Prop({ type: Boolean, default: false })
  leftAligned!: boolean
}
