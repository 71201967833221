
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'

@Component({
  components: {
    ExplanationLink,
  },
  i18n: {
    messages: {
      en: {
        local: {
          title: 'Point combinations',
        },
      },
    },
  },
})
export default class CategoryCExplanation extends Vue {}
