
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import { ProfileTypes } from '@/store/modules/profile.module'
import SelectOption from '@/common/interfaces/select-option'
import { Watch } from 'vue-property-decorator'
import LanguageTests, {
  LanguageTest,
  LANGUAGE_TEST_NONE,
  ALL_LANGUAGE_TESTS,
} from '@/data/entity/profile/language-test'
import OfficialLanguage from '@/data/entity/profile/official-language'
import FormattingUtils from '@/common/util/formatting-utils'
import PointCategory from '@/data/point-calculator/point-category'
import { LanguageTestType, LanguageTestScores } from 'shared-entities'

import CategoryPointsLabel from '../../common/CategoryPointsLabel.vue'
import LanguageSkillsInput from '../../common/LanguageSkillsInput.vue'
import LanguageFormExplanation from './LanguageFormExplanation.vue'
import { LanguageSkillScore } from '@/data/entity/profile/language-skill-score'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

@Component({
  components: {
    CategoryPointsLabel,
    LanguageSkillsInput,
  },
})
export default class LanguagesForm extends Vue {
  languageTests = LanguageTests
  secondLanguageOptions = ALL_LANGUAGE_TESTS

  get firstLanguageTest(): LanguageTest | null {
    return ProfileModuleUtils.getProfileField('firstLanguageTest')
  }

  get secondLanguageTest(): LanguageTest | null {
    return ProfileModuleUtils.getProfileField('secondLanguageTest')
  }

  get firstLanguageTestType(): LanguageTestType | null {
    const test = this.firstLanguageTest
    if (test) {
      return test.type
    }
    return null
  }

  get secondLanguageTestType(): LanguageTestType | null {
    const test = this.secondLanguageTest
    if (test) {
      return test.type
    }
    return null
  }

  get firstLanguageScores(): LanguageTestScores | null {
    return store.getters[ProfileTypes.getters.firstLanguageScores]
  }

  get secondLanguageScores(): LanguageTestScores | null {
    return store.getters[ProfileTypes.getters.secondLanguageScores]
  }

  get category(): PointCategory {
    return store.state.profile.points.categoryA.language
  }

  onFirstLanguageTestChanged(type: LanguageTestType) {
    ProfileModuleUtils.setProfileField('firstLangTestType', type)
  }

  onSecondLanguageTestChanged(type: LanguageTestType | null) {
    ProfileModuleUtils.setProfileField('secondLangTestType', type)
  }

  onFirstLanguageInput(score: LanguageSkillScore) {
    store.commit(ProfileTypes.mutations.setFirstLanguageSkillScore, score)
  }

  onSecondLanguageInput(score: LanguageSkillScore) {
    store.commit(ProfileTypes.mutations.setSecondLanguageSkillScore, score)
  }

  showFirstLangHelp() {
    this.$dialog.alert({
      title: 'Official languages',
      message:
        'Canada has two official languages, English and French. One of the languages ' +
        'you select as the First official language will be used as the main language for ' +
        'points and programs eligibility.',
    })
  }

  showHelp() {
    this.$dialog.show({
      component: LanguageFormExplanation,
      large: true,
    })
  }
}
