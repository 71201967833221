
import PointCategory from '@/data/point-calculator/point-category'
import LocalStorage from '@/data/util/local-storage'
import { LayoutTypes } from '@/store/modules/layout.module'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import store from '@/store/store'
import RouteNames from '@/ui/router/route-names'
import colors from '@/ui/styles/exports/colors.scss'
import dimensions from '@/ui/styles/exports/dimensions.scss'
import DimensionUtils from '@/ui/util/dimension-utils'
import UiUtils from '@/ui/util/ui-utils'
import WindowManager from '@/ui/util/window-manager'
import Vue from 'vue'
import Component from 'vue-class-component'
import ResponsiveUtils from '../../../util/responsive-utils'
import PointCalculatorSectionId from '../common/point-calculator-section-id'
import PointCalculatorCategorySection from './common/PointCalculatorCategorySection.vue'
import EligibilitySummary from './eligibility/EligibilitySummary.vue'
import AgeForm from './forms/categoryA/AgeForm.vue'
import EducationForm from './forms/categoryA/EducationForm.vue'
import LanguagesForm from './forms/categoryA/LanguagesForm.vue'
import WorkExperienceForm from './forms/categoryA/WorkExperienceForm.vue'
import EducationFormB from './forms/categoryB/EducationFormB.vue'
import LanguagesFormB from './forms/categoryB/LanguagesFormB.vue'
import WorkExperienceFormB from './forms/categoryB/WorkExperienceFormB.vue'
import CategoryCForm from './forms/categoryC/CategoryCForm.vue'
import CategoryDForm from './forms/categoryD/CategoryDForm.vue'
import PointCalculatorNav from './navigation/PointCalculatorNav.vue'
import PointCalculatorSpouseSelector from './spouse-selector/PointCalculatorSpouseSelector.vue'

const LS_KEY_SECTION_COLLAPSED_STATES = 'sectionCollapsedStates'
const LS_KEY_DID_VISIT = 'pointCalculatorScreenDidVisit'

const SCROLL_PADDING = 16

interface Section {
  id: PointCalculatorSectionId
  category?: PointCategory
  subSections?: SubSection[]
  component?: string
  props?: any
  noPadding?: boolean
}

interface SubSection {
  id: PointCalculatorSectionId
  component: string
  skipNavigation?: boolean
}

type SectionCollapsedStates = {
  [id: string]: boolean
}

@Component({
  head: {
    title: UiUtils.translateTitle('label.eligibility'),
  },
  components: {
    PointCalculatorNav,
    PointCalculatorSpouseSelector,
    PointCalculatorCategorySection,

    AgeForm,
    EducationForm,
    LanguagesForm,
    WorkExperienceForm,

    EducationFormB,
    LanguagesFormB,
    WorkExperienceFormB,

    CategoryCForm,
    CategoryDForm,

    EligibilitySummary,
  },
})
export default class PointCalculatorScreen extends Vue {
  sectionCollapsedStates: SectionCollapsedStates = {
    A: true,
    B: true,
    C: true,
    D: true,
  }

  isNavFixed = false
  isHeaderMoving = false
  contentsTop = 0

  get title(): string {
    return this.$ts('label.guide')
  }

  created() {
    this.initCollapsedStates()
  }

  mounted() {
    const contents = (this.$el as HTMLElement).querySelector(
      '#point-calculator-screen__contents'
    ) as HTMLElement
    this.contentsTop = UiUtils.getElementY(contents, true)

    WindowManager.addScrollListener(this.onScroll)

    if (this.$route.params.elementId) {
      Object.keys(this.sectionCollapsedStates).forEach(key => {
        if (this.sectionCollapsedStates[key]) {
          this.toggleCollapsed(key as PointCalculatorSectionId)
        }
      })

      setTimeout(() => {
        UiUtils.scrollByRouteParams(this, true)
      }, 300)
    }
  }

  beforeDestroy() {
    WindowManager.removeScrollListener(this.onScroll)
  }

  private initCollapsedStates() {
    const collapsedStates = LocalStorage.getItem(LS_KEY_SECTION_COLLAPSED_STATES)
    if (collapsedStates) {
      Object.keys(this.sectionCollapsedStates).forEach(key => {
        const value = collapsedStates[key]
        if (typeof value === 'boolean') {
          this.sectionCollapsedStates[key] = collapsedStates[key]
        }
      })
    }
  }

  private onScroll(scrollY: number) {
    this.updateNavPosition(scrollY)
  }

  private updateNavPosition(scrollY: number) {
    this.isNavFixed = scrollY > this.contentsTop - 24
  }

  get sections(): Section[] {
    const points = store.state.profile.points
    const sections: Section[] = [
      {
        id: 'SPOUSE',
        component: 'PointCalculatorSpouseSelector',
        props: {
          hideInfo: true,
        },
      },
      {
        id: 'A',
        category: points.categoryA,
        subSections: [
          {
            id: 'A_AGE',
            component: 'AgeForm',
          },
          {
            id: 'A_EDUCATION',
            component: 'EducationForm',
          },
          {
            id: 'A_LANGUAGES',
            component: 'LanguagesForm',
          },
          {
            id: 'A_WORK_EXPERIENCE',
            component: 'WorkExperienceForm',
          },
        ],
      },
      {
        id: 'B',
        category: points.categoryB,
        subSections: [
          {
            id: 'B_SPOUSE_SWITCH',
            component: 'PointCalculatorSpouseSelector',
            skipNavigation: true,
          },
        ],
      },
      {
        id: 'C',
        category: points.categoryC,
        component: 'CategoryCForm',
      },
      {
        id: 'D',
        category: points.categoryD,
        component: 'CategoryDForm',
        noPadding: true,
      },
    ]

    if (ProfileModuleUtils.getProfileField('hasSpouse')) {
      const sectionB = sections.find(it => it.id === 'B')!
      sectionB.subSections = sectionB.subSections!.concat([
        {
          id: 'B_EDUCATION',
          component: 'EducationFormB',
        },
        {
          id: 'B_LANGUAGES',
          component: 'LanguagesFormB',
        },
        {
          id: 'B_WORK_EXPERIENCE',
          component: 'WorkExperienceFormB',
        },
      ])
    }

    return sections
  }

  onSectionSelected(id: PointCalculatorSectionId) {
    const parentId = this.getSectionParentId(id)
    if (parentId && this.sectionCollapsedStates[parentId]) {
      this.sectionCollapsedStates[parentId] = false
    }

    this.$nextTick(() => {
      const element = UiUtils.getElementForRef(this.$refs, id)
      this.$store.commit(LayoutTypes.mutations.setPointCalculatorNavOpen, false)

      if (element) {
        this.$nextTick(() => {
          let y = this.getElementY(element) - SCROLL_PADDING

          // On phones there's a total points subheader on top which gets
          // shown when scrolling up, so we have to subtract additional height from `y`.
          if (ResponsiveUtils.isPhone() && y < UiUtils.getScrollTop()) {
            y -= DimensionUtils.getDimension('mobilePointsHeight')
          }

          WindowManager.scrollTo(y, () => {
            this.highlightSectionElement(element)
          })
        })
      }
    })
  }

  private getSectionParentId(id: PointCalculatorSectionId): PointCalculatorSectionId | null {
    if (id.length > 1 && id[1] === '_') {
      return id[0] as PointCalculatorSectionId
    }
    return null
  }

  private highlightSectionElement(element: HTMLElement) {
    element.style.backgroundColor = colors.highlight
    setTimeout(() => {
      element.style.backgroundColor = 'unset'
    }, 500)
  }

  private get headerHeight(): number {
    return parseInt(dimensions.headerHeight, 10)
  }

  private getElementY(element: HTMLElement): number {
    const scrollY = window.scrollY || window.pageYOffset
    return element.getBoundingClientRect().top + scrollY - this.headerHeight
  }

  toggleCollapsed(sectionId: PointCalculatorSectionId) {
    this.sectionCollapsedStates[sectionId] = !this.sectionCollapsedStates[sectionId]
    LocalStorage.putItem(LS_KEY_SECTION_COLLAPSED_STATES, this.sectionCollapsedStates)
  }

  get isNavOpen(): boolean {
    return store.state.layout.isPointCalculatorNavOpen
  }

  setNavOpen(isOpen: boolean) {
    this.$store.commit(LayoutTypes.mutations.setPointCalculatorNavOpen, isOpen)
  }

  goToPlans() {
    this.$router.push({ name: RouteNames.PREMIUM })
  }

  signIn() {
    this.$router.push({ name: RouteNames.SIGN_IN })
  }
}
