
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'
import { WORK_EXPERIENCE_SCORES } from '@/data/point-calculator/categories/core/work-experience-point-calculator'

interface WorkExperienceScore {
  startYears: number
  endYears: number
  pointsWithSpouse: number
  pointsWithoutSpouse: number
}

@Component({
  components: {
    ExplanationLink,
  },
})
export default class WorkExperienceFormExplanation extends Vue {
  scores: WorkExperienceScore[] = []

  created() {
    let prevMinYears = 0
    let prevPointsWithSpouse = 0
    let prevPointsWithoutSpouse = 0
    for (let i = 0; i < WORK_EXPERIENCE_SCORES.length; i++) {
      const score = WORK_EXPERIENCE_SCORES[i]
      this.scores.push({
        startYears: prevMinYears,
        endYears: score.minYears - 1,
        pointsWithSpouse: prevPointsWithSpouse,
        pointsWithoutSpouse: prevPointsWithoutSpouse,
      })

      prevMinYears = score.minYears
      prevPointsWithSpouse = score.pointsWithSpouse
      prevPointsWithoutSpouse = score.pointsWithoutSpouse
    }

    this.scores.push({
      startYears: prevMinYears,
      endYears: Infinity,
      pointsWithSpouse: prevPointsWithSpouse,
      pointsWithoutSpouse: prevPointsWithoutSpouse,
    })
  }
}
