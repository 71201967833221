
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'
import { AGE_BRACKETS } from '@/data/point-calculator/categories/core/age-point-calculator'

interface AgeBracket {
  startAge: number
  endAge: number
  pointsWithSpouse: number
  pointsWithoutSpouse: number
}

@Component({
  components: {
    ExplanationLink,
  },
  i18n: {
    messages: {
      en: {
        local: {
          minAge: '{years} years of age or less',
          maxAge: '{years} years of age or more',
          ageRange: '{start} to {end} years of age',
          yearsOfAge: '{years} years of age',
        },
      },
    },
  },
})
export default class AgeFormExplanation extends Vue {
  ageBrackets: AgeBracket[] = []

  created() {
    let previousMaxAge = -1
    for (let i = 0; i < AGE_BRACKETS.length; i++) {
      const bracket = AGE_BRACKETS[i]
      this.ageBrackets.push({
        startAge: previousMaxAge + 1,
        endAge: bracket.maxAge,
        pointsWithSpouse: bracket.pointsWithSpouse,
        pointsWithoutSpouse: bracket.pointsWithoutSpouse,
      })
      previousMaxAge = bracket.maxAge
    }
  }
}
