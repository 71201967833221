import EligibilityStatus from '@/data/entity/programs/eligibility-status.entity'

export default class EligibilityUiUtils {
  /**
   * Return an icon name that should be used for the given eligibility status.
   */
  static getStatusIcon(status: EligibilityStatus): string {
    switch (status) {
      case EligibilityStatus.NOT_ELIGIBLE:
        return 'close'
      case EligibilityStatus.ELIGIBLE:
        return 'check'
      default:
        return 'info'
    }
  }
}
