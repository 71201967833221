
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import { NocGroup } from 'shared-entities'

import JobOfferForm from '../../../common/JobOfferForm.vue'
import ProofOfFundsForm from '../../common/ProofOfFundsForm.vue'

import JOB_OFFER_DESCRIPTION from './fst-job-offer-description.partial'

@Component({
  components: {
    JobOfferForm,
    ProofOfFundsForm,
  },
  i18n: {
    messages: {
      en: {
        local: {
          workExpSwitch:
            'Do you have at least 2 years of Canadian or foreign work experience within ' +
            'the last 5 years in a skilled trade under key groups of NOC B:',
          workExpSwitchDesc:
            '<ul><li>Major Group 72, industrial, electrical and construction trades</li>' +
            '<li>Major Group 73, maintenance and equipment operation trades</li>' +
            '<li>Major Group 82, supervisors and technical jobs in natural resources, agriculture and related production</li>' +
            '<li>Major Group 92, processing, manufacturing and utilities supervisors and central control operators</li>' +
            '<li>Minor Group 632, chefs and cooks</li>' +
            '<li>Minor Group 633, butchers and bakers</li></ul>' +
            '<br><p>Work must have been full-time or an equal amount in part-time.</p>' +
            '<p>The 2 years of experience must be counted starting from the date on which you ' +
            'first became qualified to practice your occupation.</p>',

          jobOfferSwitch:
            'Is your job offer for a full-time employment with a total period of at least 1 year, ' +
            'and satisfies the following conditions?',

          certDesc:
            'You must:' +
            '<ul><li>have passed a certification exam</li>' +
            '<li>meet all the requirements to practice your trade in that province or territory.</li><ul>',
        },
      },
    },
  },
})
export default class FstEligibilityForm extends Vue {
  jobOfferDesc = JOB_OFFER_DESCRIPTION

  get hasNocBJobOffer(): boolean {
    return ProfileModuleUtils.getProfileField('jobOffer') === NocGroup.NOC_B
  }

  get hasWorkExp(): boolean {
    return ProfileModuleUtils.getProfileField('totalWorkExp') >= 2
  }

  get hasFstWorkExp(): boolean {
    return ProfileModuleUtils.getFstField('hasWorkExp')
  }

  setHasFstWorkExp(value: boolean) {
    ProfileModuleUtils.setFstField('hasWorkExp', value)
  }

  get hasJobOffer(): boolean {
    return ProfileModuleUtils.getFstField('hasJobOffer')
  }

  setHasJobOffer(value: boolean) {
    ProfileModuleUtils.setFstField('hasJobOffer', value)
  }

  get hasCert(): boolean {
    return ProfileModuleUtils.getProfileField('hasCert')
  }

  setHasCert(value: boolean) {
    ProfileModuleUtils.setProfileField('hasCert', value)
  }
}
