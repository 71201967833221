
import Vue from 'vue'
import Component from 'vue-class-component'

import PointCalculatorHelpLabel from '../common/PointCalculatorHelpLabel.vue'
import EligibilityCriterionResult from '@/data/entity/programs/eligibility-criterion-result.entity'
import { Prop } from 'vue-property-decorator'
import EligibilityCriterionDialog from './EligibilityCriterionDialog.vue'
import ProgramId from '@/data/entity/programs/program-id.entity'
import EligibilityUiUtils from './common/eligibility-ui-utils'

@Component({
  components: {
    PointCalculatorHelpLabel,
  },
})
export default class EligibilityCriterionView extends Vue {
  @Prop({ type: String, required: true })
  programId!: ProgramId
  @Prop({ required: true })
  result!: EligibilityCriterionResult

  get icon(): string {
    return EligibilityUiUtils.getStatusIcon(this.result.status)
  }

  get title(): string {
    return this.$ts('eligibility.criteria.' + this.result.criterion.title)
  }

  showHelp() {
    this.$dialog.show({
      component: EligibilityCriterionDialog,
      props: {
        programId: this.programId,
        result: this.result,
      },
      large: true,
    })
  }
}
