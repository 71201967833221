
import Vue from 'vue'
import Component from 'vue-class-component'
import PointCategory from '@/data/point-calculator/point-category'
import { Prop } from 'vue-property-decorator'

import PointCategoryHeader from '../../common/PointCategoryHeader.vue'
import PointCalculatorCategoryTotal from './PointCalculatorCategoryTotal.vue'

/**
 * A collapsible section for a point category on the point calculator screen.
 */
@Component({
  components: {
    PointCategoryHeader,
    PointCalculatorCategoryTotal,
  },
})
export default class PointCalculatorCategorySection extends Vue {
  @Prop({ required: true })
  category!: PointCategory

  @Prop({ type: Boolean, default: false })
  isCollapsed!: boolean

  toggleCollapsed() {
    this.$emit('toggle-collapsed')
  }
}
