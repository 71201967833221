
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'

@Component({
  components: {
    ExplanationLink,
  },
  i18n: {
    messages: {
      en: {
        local: {
          header15:
            'Scored NCLC 7 or higher on all four French language skills and scored CLB 4 or lower in English (or didn’t take an English test)',
          header30:
            'Scored NCLC 7 or higher on all four French language skills and scored CLB 5 or higher on all four English skills',
        },
      },
    },
  },
})
export default class CategoryDFrenchBonusExplanation extends Vue {}
