
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import AgeOptions from '@/ui/business-utils/age-options'

import AgeFormExplanation from './AgeFormExplanation.vue'
import CategoryPointsLabel from '../../common/CategoryPointsLabel.vue'
import PointCategory from '@/data/point-calculator/point-category'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import SelectOption from '@/common/interfaces/select-option'

@Component({
  components: {
    CategoryPointsLabel,
  },
})
export default class AgeForm extends Vue {
  ageOptions: SelectOption[] = AgeOptions.generate()

  get category(): PointCategory {
    return store.state.profile.points.categoryA.age
  }

  get age(): number | null {
    return ProfileModuleUtils.getProfileField('age')
  }

  setAge(value: number) {
    ProfileModuleUtils.setProfileField('age', value)
  }

  showHelp() {
    this.$dialog.show({
      component: AgeFormExplanation,
      large: true,
    })
  }
}
