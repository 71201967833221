
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { LanguageTest, CLBSkillMapping } from '@/data/entity/profile/language-test'
import AppForm from '@/ui/components/form/AppForm.vue'
import { LanguageTestScores, LanguageSkill, LANGUAGE_SKILLS } from 'shared-entities'
import { LanguageSkillScore } from '@/data/entity/profile/language-skill-score'
import SelectOption from '@/common/interfaces/select-option'

type ScoreOptions = {
  [key in LanguageSkill]: SelectOption[]
}

/**
 * Renders four inputs for each language skill, displays current scores
 * and allows editing them.
 */
@Component
export default class LanguageSkillsInput extends Vue {
  @Prop({ required: true })
  languageTest!: LanguageTest

  @Prop({ required: true })
  scores!: LanguageTestScores

  skills = LANGUAGE_SKILLS

  scoreOptions: ScoreOptions = {
    r: [],
    w: [],
    l: [],
    s: [],
  }

  created() {
    this.updateScoreOptions()
  }

  getSkillTitle(skill: LanguageSkill): string {
    return 'profile.language.skills.' + skill
  }

  getScore(skill: LanguageSkill): number {
    return this.scores[skill]
  }

  onInput(skill: LanguageSkill, clb: number) {
    const score: LanguageSkillScore = {
      skill,
      value: clb,
    }
    this.$emit('input', score)
  }

  private updateScoreOptions() {
    const clbMapping = this.languageTest.clbMapping
    for (let i = 0; i < LANGUAGE_SKILLS.length; i++) {
      const skill = LANGUAGE_SKILLS[i]
      const skillMapping = clbMapping[skill]
      const options: SelectOption[] = [this.makeScoreOption(skillMapping, -1)]

      for (let i = 0; i < skillMapping.length; i++) {
        options.push(this.makeScoreOption(skillMapping, i))
      }

      this.scoreOptions[skill] = options
    }
  }

  private makeScoreOption(skillMapping: CLBSkillMapping, index: number): SelectOption {
    let clbScoreLabel = 'CLB'
    let testScoreLabel = this.languageTest.type
    let clb = 0
    if (index === -1) {
      clb = skillMapping[0].clb - 1
      const maxScore = skillMapping[0].minScore - (this.languageTest.id === 'IELTS' ? 0.5 : 1)
      clbScoreLabel += ` 1 - ${clb}`
      testScoreLabel += ` 0 - ${maxScore}`
    } else {
      const minScore = skillMapping[index].minScore
      clb = skillMapping[index].clb
      if (index === skillMapping.length - 1) {
        clbScoreLabel += ` ${clb}+`
        testScoreLabel += ` ${minScore}+`
      } else {
        const nextMapping = skillMapping[index + 1]
        const maxScore = nextMapping.minScore - (this.languageTest.id === 'IELTS' ? 0.5 : 1)
        clbScoreLabel += ` ${clb}`

        if (minScore === maxScore) {
          testScoreLabel += ' ' + minScore
        } else {
          testScoreLabel += ` ${minScore} - ${maxScore}`
        }
      }
    }

    const title = `${testScoreLabel} (${clbScoreLabel})`

    return {
      id: clb,
      title,
    }
  }

  @Watch('languageTest')
  private onLanguageTestChanged() {
    this.updateScoreOptions()
  }
}
