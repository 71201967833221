
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import store from '@/store/store'

import FormattingUtils from '@/common/util/formatting-utils'
import PointCategory from '@/data/point-calculator/point-category'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

import CategoryPointsLabel from '../../common/CategoryPointsLabel.vue'
import WorkExperienceFormBExplanation from './WorkExperienceFormBExplanation.vue'
import WorkExpInput from '../../common/WorkExpInput.vue'

@Component({
  components: {
    CategoryPointsLabel,
    WorkExpInput,
  },
})
export default class WorkExperienceFormB extends Vue {
  get years(): number {
    return ProfileModuleUtils.getSpouseField('workExp')
  }

  get category(): PointCategory {
    return store.state.profile.points.categoryB.workExp
  }

  showHelp() {
    this.$dialog.show({
      component: WorkExperienceFormBExplanation,
      large: true,
    })
  }

  onInput(years: number) {
    ProfileModuleUtils.setSpouseField('workExp', years)
  }
}
