
import Vue from 'vue'
import Component from 'vue-class-component'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import { NocGroup } from 'shared-entities'

import NocExplanation from './NocExplanation.vue'

@Component
export default class JobOfferForm extends Vue {
  options = [
    { id: -1, title: 'label.noneOr', args: { value: 'NOC-C/D' } },
    { id: NocGroup.NOC_00, title: 'NOC-00' },
    { id: NocGroup.NOC_0, title: 'NOC-0' },
    { id: NocGroup.NOC_A, title: 'NOC-A' },
    { id: NocGroup.NOC_B, title: 'NOC-B' },
  ]

  get jobOfferOptionId(): number {
    return ProfileModuleUtils.getProfileField('jobOffer') || -1
  }

  setJobOfferOption(optionId: number) {
    const arrangedEmploymentType = optionId === -1 ? null : optionId
    ProfileModuleUtils.setProfileField('jobOffer', arrangedEmploymentType)
  }

  showHelp() {
    this.$dialog.show({
      component: NocExplanation,
      large: true,
    })
  }
}
