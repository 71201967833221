
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import { Watch } from 'vue-property-decorator'
import store from '@/store/store'
import { EDUCATION_LEVEL_OPTIONS } from '@/data/point-calculator/categories/core/education-point-calculator'

import CategoryPointsLabel from '../../common/CategoryPointsLabel.vue'
import EducationFormExplanation from './EducationFormExplanation.vue'
import FormattingUtils from '@/common/util/formatting-utils'
import PointCategory from '@/data/point-calculator/point-category'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

@Component({
  components: {
    CategoryPointsLabel,
  },
})
export default class EducationForm extends Vue {
  educationLevels = EDUCATION_LEVEL_OPTIONS

  get category(): PointCategory {
    return store.state.profile.points.categoryA.education
  }

  get educationLevelId(): number | null {
    return ProfileModuleUtils.getProfileField('education')
  }

  onInput(optionId: number | null) {
    ProfileModuleUtils.setProfileField('education', optionId)
  }

  showHelp() {
    this.$dialog.show({
      component: EducationFormExplanation,
      large: true,
    })
  }
}
