
import allPrograms from '@/data/eligibility-calculator/all-programs'
import Program from '@/data/entity/programs/program.entity'
import store from '@/store/store'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import EligibilityUiUtils from './common/eligibility-ui-utils'

@Component
export default class EligibilityProgramSelector extends Vue {
  @Prop({ required: true })
  value!: Program

  programs = allPrograms

  indicatorStyle = {
    left: '0',
    right: '0',
  }

  isSelected(program: Program): boolean {
    return program === this.value
  }

  getStatusIcon(program: Program): string {
    const eligibility = store.state.profile.eligibility
    const status = eligibility[program.id].status
    return EligibilityUiUtils.getStatusIcon(status)
  }

  selectProgram(program: Program) {
    this.$emit('input', program)
  }

  @Watch('value')
  private onValueChanged() {
    const index = this.programs.indexOf(this.value)
    if (index >= 0) {
      this.indicatorStyle.left = `${100 * index}%`
      this.indicatorStyle.right = `-${100 * index}%`
    }
  }
}
