
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

/**
 * A text label that shows `See {linkText}` with the link text linking to a supplied URL.
 */
@Component
export default class ExplanationLink extends Vue {
  @Prop({ type: String, required: true })
  link!: string

  @Prop({ type: String, default: 'label.moreDetails' })
  linkText!: string

  @Prop({ type: Boolean, default: false })
  alignLeft!: boolean

  get style() {
    if (this.alignLeft) {
      return {
        textAlign: 'left',
      }
    }
    return null
  }
}
