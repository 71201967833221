
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import store from '@/store/store'
import PointCategory from '@/data/point-calculator/point-category'
import PointCalculatorNavigationItem from './point-calculator-navigation-item'

import PointCalculatorTotalPoints from '../common/PointCalculatorTotalPoints.vue'
import PointCalculatorNavSection from './PointCalculatorNavSection.vue'
import PointCalculatorNavLatestRoundPoints from './PointCalculatorNavLatestRoundPoints.vue'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import PointCalculatorSectionId from '../../common/point-calculator-section-id'

@Component({
  components: {
    PointCalculatorNavSection,
    PointCalculatorTotalPoints,
    PointCalculatorNavLatestRoundPoints,
  },
})
export default class PointCalculatorNav extends Vue {
  @Prop({ type: Boolean, default: false })
  typeDrawer!: boolean

  get items(): PointCalculatorNavigationItem[] {
    const hasSpouse = ProfileModuleUtils.getProfileField('hasSpouse')
    return [this.getCategoryAItem(), this.getCategoryBItem(hasSpouse)]
  }

  get groupedItems(): PointCalculatorNavigationItem[] {
    return [this.getCategoryCItem(), this.getCategoryDItem()]
  }

  getCategoryAItem(): PointCalculatorNavigationItem {
    const category = store.state.profile.points.categoryA
    return {
      section: 'A',
      category,
      subItems: [
        {
          section: 'A_AGE',
          category: category.age,
        },
        {
          section: 'A_EDUCATION',
          category: category.education,
        },
        {
          section: 'A_LANGUAGES',
          category: category.language,
        },
        {
          section: 'A_WORK_EXPERIENCE',
          category: category.workExp,
        },
      ],
    }
  }

  getCategoryBItem(hasSpouse: boolean): PointCalculatorNavigationItem {
    const category = store.state.profile.points.categoryB
    return {
      section: 'B',
      category,
      disabled: !hasSpouse,
      subItems: hasSpouse
        ? [
            {
              section: 'B_EDUCATION',
              category: category.education,
            },
            {
              section: 'B_LANGUAGES',
              category: category.language,
            },
            {
              section: 'B_WORK_EXPERIENCE',
              category: category.workExp,
            },
          ]
        : void 0,
    }
  }

  getCategoryCItem(): PointCalculatorNavigationItem {
    const category = store.state.profile.points.categoryC
    return {
      section: 'C',
      category,
    }
  }

  getCategoryDItem(): PointCalculatorNavigationItem {
    const category = store.state.profile.points.categoryD
    return {
      section: 'D',
      category,
    }
  }

  onItemClicked(sectionId: PointCalculatorSectionId) {
    this.$emit('select', sectionId)
  }
}
