
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'
import { WORK_EXPERIENCE_SCORES } from '@/data/point-calculator/categories/core/work-experience-point-calculator'

interface WorkExperienceScore {
  startYears: number
  endYears: number
  points: number
}

@Component({
  components: {
    ExplanationLink,
  },
  i18n: {
    messages: {
      en: {
        local: {
          spousesCanadianWorkExp: "Spouse's Canadian work experience",
        },
      },
    },
  },
})
export default class WorkExperienceFormBExplanation extends Vue {
  scores: WorkExperienceScore[] = []

  created() {
    this.$t
    let prevMinYears = 0
    let prevPoints = 0
    for (let i = 0; i < WORK_EXPERIENCE_SCORES.length; i++) {
      const score = WORK_EXPERIENCE_SCORES[i]
      this.scores.push({
        startYears: prevMinYears,
        endYears: score.minYears - 1,
        points: prevPoints,
      })

      prevMinYears = score.minYears
      prevPoints = score.pointsForSpouse
    }

    this.scores.push({
      startYears: prevMinYears,
      endYears: Infinity,
      points: prevPoints,
    })
  }
}
