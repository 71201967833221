
import Vue from 'vue'
import Component from 'vue-class-component'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import { NocGroup } from 'shared-entities'

import JobOfferForm from '../../../common/JobOfferForm.vue'
import CanadianSiblingSwitch from '../../../common/CanadianSiblingSwitch.vue'
import ProofOfFundsForm from '../../common/ProofOfFundsForm.vue'

@Component({
  components: {
    JobOfferForm,
    CanadianSiblingSwitch,
    ProofOfFundsForm,
  },
  i18n: {
    messages: {
      en: {
        local: {
          workExpSwitch:
            'Do you have at least one year of Canadian or foreign work experience in your primary occupation?',
          jobOfferSwitch:
            'Is your job offer for a full-time employment with a total period of at least 1 year, ' +
            'and satisfies the following conditions?',
          canadianEducationSwitch:
            'Did you complete at least 2 ' +
            'academic years of full-time study (in a program at least 2 years long) ' +
            'at a secondary or post-secondary school in Canada?',
          canadianEducationDesc:
            'Full-time study means at least 15 hours of classes per week. ' +
            'You must have stayed in good academic standing (as set out by the school) during that time.',
          spouseCanadianEducationSwitch:
            'Did your spouse or common-law partner complete at least 2 ' +
            'academic years of full-time study (in a program at least 2 years long) ' +
            'at a secondary or post-secondary school in Canada?',

          canadianRelativesSwitch:
            'Do you, or your spouse or common-law partner (if they are coming with you to Canada) ' +
            'have a relative who is:',
        },
      },
    },
  },
})
export default class FswEligibilityForm extends Vue {
  workExpExplanation = ''
  jobOfferDesc = ''
  canadianRelativesDesc = ''

  mounted() {
    this.init()
  }

  private init() {
    import('./fsw-work-exp.partial').then(html => (this.workExpExplanation = html.default as any))
    import('./fsw-job-offer-description.partial').then(
      html => (this.jobOfferDesc = html.default as any)
    )
    import('./fsw-canadian-relatives.partial').then(html => {
      this.canadianRelativesDesc = html.default as any
    })
  }

  get hasWorkExp(): boolean {
    return ProfileModuleUtils.getProfileField('totalWorkExp') >= 1
  }

  get hasFswWorkExp(): boolean {
    return ProfileModuleUtils.getFswField('hasWorkExp')
  }

  setHasFswWorkExp(value: boolean) {
    ProfileModuleUtils.setFswField('hasWorkExp', value)
  }

  get hasJobOffer(): boolean {
    const jobOffer = ProfileModuleUtils.getProfileField('jobOffer')
    return jobOffer !== null && jobOffer >= NocGroup.NOC_B
  }

  get hasFswJobOffer(): boolean {
    return ProfileModuleUtils.getFswField('hasJobOffer')
  }

  setHasFswJobOffer(value: boolean) {
    ProfileModuleUtils.setFswField('hasJobOffer', value)
  }

  get hasSpouse(): boolean {
    return ProfileModuleUtils.getProfileField('hasSpouse')
  }

  get hasFswCanadianEducation(): boolean {
    return ProfileModuleUtils.getFswField('canadianEducation')
  }

  setHasFswCanadianEducation(value: boolean) {
    ProfileModuleUtils.setFswField('canadianEducation', value)
  }

  get spouseHasCanadianEducation(): boolean {
    return ProfileModuleUtils.getFswField('spouseCanadianEducation')
  }

  setSpouseHasCanadianEducation(value: boolean) {
    ProfileModuleUtils.setFswField('spouseCanadianEducation', value)
  }

  get hasCanadianSibling(): boolean {
    return ProfileModuleUtils.getProfileField('hasCanadianSibling')
  }

  get hasRelativesInCanada(): boolean {
    return ProfileModuleUtils.getFswField('hasRelativesInCanada')
  }

  setHasRelativesInCanada(value: boolean) {
    ProfileModuleUtils.setFswField('hasRelativesInCanada', value)
  }
}
