
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'
import { EDUCATION_LEVEL_OPTIONS } from '@/data/point-calculator/categories/core/education-point-calculator'

@Component({
  components: {
    ExplanationLink,
  },
  i18n: {
    messages: {
      en: {
        local: {
          spousesEducationLevel: 'Spouse’s or common-law partner’s level of education',
        },
      },
    },
  },
})
export default class EducationFormBExplanation extends Vue {
  educationLevelOptions = EDUCATION_LEVEL_OPTIONS
}
