
import Vue from 'vue'
import Component from 'vue-class-component'
import PointCategory from '@/data/point-calculator/point-category'
import { Prop } from 'vue-property-decorator'
import FormattingUtils from '@/common/util/formatting-utils'

import PointCalculatorHelpLabel from './PointCalculatorHelpLabel.vue'

/**
 * Label that shows the subtotal of points for a category
 * Has a question mark button which emits the `show-help` event when clicked.
 */
@Component({
  components: {
    PointCalculatorHelpLabel,
  },
})
export default class CategoryPointsLabel extends Vue {
  @Prop({ required: true })
  category!: PointCategory

  get points(): string {
    return FormattingUtils.formatCategoryPoints(this.category)
  }
}
