
import Vue from 'vue'
import Component from 'vue-class-component'
import PointCategory from '@/data/point-calculator/point-category'
import { Prop } from 'vue-property-decorator'
import FormattingUtils from '@/common/util/formatting-utils'

/**
 * A text label that renders the total of points for a given point category.
 * Intended to be used with parent categories, such as A, B etc.
 */
@Component
export default class PointCalculatorCategoryTotal extends Vue {
  @Prop({ required: true })
  category!: PointCategory

  get totalCategoryPoints(): string {
    return FormattingUtils.formatCategoryPoints(this.category)
  }
}
