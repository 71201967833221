
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import { CategoryC } from '@/data/point-calculator/categories/point-calculator-category-c'
import FormattingUtils from '@/common/util/formatting-utils'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

import CategoryCExplanation from './CategoryCExplanation.vue'
import WorkExpInput from '../../common/WorkExpInput.vue'

@Component({
  components: {
    WorkExpInput,
  },
})
export default class CategoryCForm extends Vue {
  get foreignWorkExperience(): number {
    return ProfileModuleUtils.getProfileField('foreignWorkExp')
  }

  get hasCertificate(): boolean {
    return ProfileModuleUtils.getProfileField('hasCert')
  }

  updateForeignWorkExperience(years: number) {
    ProfileModuleUtils.setProfileField('foreignWorkExp', years)
  }

  updateHasCertificate(value: boolean) {
    ProfileModuleUtils.setProfileField('hasCert', value)
  }

  private get categoryC(): CategoryC {
    return store.state.profile.points.categoryC
  }

  get educationPlusLanguagePoints(): string {
    return FormattingUtils.formatCategoryPoints(this.categoryC.educationPlusLanguage)
  }

  get educationPlusCanadianWorkExpPoints(): string {
    return FormattingUtils.formatCategoryPoints(this.categoryC.educationPlusCanadianWorkExp)
  }

  get foreignWorkExpPlusLanguagePoints(): string {
    return FormattingUtils.formatCategoryPoints(this.categoryC.foreignWorkExpPlusLanguage)
  }

  get foreignWorkExpPlusCanadianWorkExpPoints(): string {
    return FormattingUtils.formatCategoryPoints(this.categoryC.foreignWorkExpPlusCanadianWorkExp)
  }

  get certificatePlusLanguagePoints(): string {
    return FormattingUtils.formatCategoryPoints(this.categoryC.certificatePlusLanguage)
  }

  showHelp() {
    this.$dialog.show({
      component: CategoryCExplanation,
      large: true,
    })
  }
}
