
import Vue from 'vue'
import Component from 'vue-class-component'
import PoolDataRepository, { PoolDataModel } from '@/data/repository/pool-data.repository'
import RouteNames from '@/ui/router/route-names'
import { PoolData } from 'shared-entities'

@Component
export default class PointCalculatorNavLatestRoundPoints extends Vue {
  currentCurvePoint = ''
  isCurvePointLoading = true
  subscriberId: number | null = null

  mounted() {
    this.subscriberId = PoolDataRepository.subscribeToPoolData(this.onPoolDataUpdated)
  }

  beforeDestroy() {
    if (this.subscriberId) {
      PoolDataRepository.unsubscribeFromPoolData(this.subscriberId)
    }
  }

  private onPoolDataUpdated(poolData: PoolDataModel | null) {
    if (poolData) {
      this.isCurvePointLoading = false
      this.currentCurvePoint =
        poolData.latestRound.lowestScore + (poolData.latestRound.program ? '*' : '')
    }
  }

  goToRounds() {
    this.$router.push({ name: RouteNames.ROUNDS })
  }
}
