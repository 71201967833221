
import Vue from 'vue'
import Component from 'vue-class-component'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

@Component({
  i18n: {
    messages: {
      en: {
        local: {
          provincialNominationSwitch: 'Do you have a provincial or territorial nomination?',
        },
      },
    },
  },
})
export default class ProvincialNominationSwitch extends Vue {
  get hasProvincialNomination(): boolean {
    return ProfileModuleUtils.getProfileField('hasProvNomination')
  }

  setHasProvincialNomination(value: boolean) {
    ProfileModuleUtils.setProfileField('hasProvNomination', value)
  }
}
