
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'
import { EDUCATION_LEVEL_OPTIONS } from '@/data/point-calculator/categories/core/education-point-calculator'

@Component({
  components: {
    ExplanationLink,
  },
})
export default class EducationFormExplanation extends Vue {
  educationLevelOptions = EDUCATION_LEVEL_OPTIONS
}
