
import Vue from 'vue'
import Component from 'vue-class-component'

import ExplanationLink from '../../common/ExplanationLink.vue'
import { SPOUSE_LANGUAGE_SCORES } from '@/data/point-calculator/categories/core/language-point-calculator'

interface LanguageScore {
  startCLB: number
  endCLB: number
  points: number
}

@Component({
  components: {
    ExplanationLink,
  },
  i18n: {
    messages: {
      en: {
        local: {
          maxPoints: 'Maximum 20 points for section',
          maxPointsPerAbility: 'Maximum 5 points per ability',
          explanationText: 'how your test scores are converted into CLB equivalents',
        },
      },
    },
  },
})
export default class LanguageFormBExplanation extends Vue {
  scores: LanguageScore[] = []

  created() {
    let previousScore = 0
    let prevPoints = 0
    for (let i = 0; i < SPOUSE_LANGUAGE_SCORES.length; i++) {
      const score = SPOUSE_LANGUAGE_SCORES[i]
      this.scores.push({
        startCLB: previousScore,
        endCLB: score.minCLB - 1,
        points: prevPoints,
      })

      previousScore = score.minCLB
      prevPoints = score.pointsWithSpouse
    }

    this.scores.push({
      startCLB: previousScore,
      endCLB: Infinity,
      points: prevPoints,
    })
  }
}
