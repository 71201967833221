
import Vue from 'vue'
import Component from 'vue-class-component'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import SelectOption from '@/common/interfaces/select-option'
import { Prop } from 'vue-property-decorator'
import { NocGroup } from 'shared-entities'

@Component({
  i18n: {
    messages: {
      en: {
        local: {
          workPermit: 'Are you currently able to legally work in Canada?',
          workPermitDesc:
            'If you are currently able to legally work in Canada and have a valid job offer, ' +
            'then you do not need a proof of funds',
          familySize:
            'How many family members, including yourself, are you planning to bring to Canada?',
          funds: 'How much money (in Canadian dollars) are you planning to bring to Canada?',
        },
      },
    },
  },
})
export default class ProofOfFundsForm extends Vue {
  @Prop({ type: String, required: true })
  mode!: 'fst' | 'fsw'

  familySizeRule = (value: string) => {
    const number = Number(value) || 0
    if (number >= 1) {
      return true
    }
    return 'Invalid value: must be at least 1'
  }

  get funds(): string {
    return ProfileModuleUtils.getEligibilityField('funds').toString()
  }

  setFunds(funds: string) {
    ProfileModuleUtils.setEligibilityField('funds', Number(funds) || 0)
  }

  get familySize(): string {
    return ProfileModuleUtils.getEligibilityField('familySize').toString()
  }

  setFamilySize(value: string) {
    ProfileModuleUtils.setEligibilityField('familySize', Number(value) || 0)
  }

  get hasWorkPermit(): boolean {
    return ProfileModuleUtils.getEligibilityField('workPermit')
  }

  setHasWorkPermit(value: boolean) {
    ProfileModuleUtils.setEligibilityField('workPermit', value)
  }

  get proofOfFundsNeeded(): boolean {
    let hasJobOffer = false
    if (this.mode === 'fst') {
      hasJobOffer =
        ProfileModuleUtils.getProfileField('jobOffer') === NocGroup.NOC_B &&
        ProfileModuleUtils.getFstField('hasJobOffer')
    } else if (this.mode === 'fsw') {
      hasJobOffer =
        ProfileModuleUtils.getProfileField('jobOffer') !== null &&
        ProfileModuleUtils.getFswField('hasJobOffer')
    }
    return !hasJobOffer || !this.hasWorkPermit
  }
}
