
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop } from 'vue-property-decorator'
import store from '@/store/store'
import { ProfileTypes } from '@/store/modules/profile.module'
import { AuthTypes } from '@/store/modules/auth.module'
import { Customer, Permission } from 'shared-entities'
import PermissionChecker from '@/data/claims/permission-checker'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

let memorizedCanSwitchSpouse = false

@Component({
  i18n: {
    messages: {
      en: {
        local: {
          label:
            'Do you have a spouse coming with you to Canada who is not a Canadian citizen or permanent resident?',
          spouseInfo:
            'If your spouse or partner is not coming with you to Canada, or they are a Canadian citizen or permanent resident, you will earn points as if you don’t have a spouse or partner.',
          switchSpouse: 'Set spouse as the main applicant',
          switchSpouseDescription:
            "Swap your own profile with your spouse's. You can easily undo this action if you need to. Do not forget to fill out sections C and D for additional points.",
        },
      },
    },
  },
})
export default class PointCalculatorSpouseSelector extends Vue {
  @Prop({ type: Boolean, default: false })
  hideInfo!: boolean

  canSwitchSpouse = memorizedCanSwitchSpouse

  mounted() {
    this.updateValue()
  }

  private async updateValue() {
    this.canSwitchSpouse = await PermissionChecker.check('switchSpouse')
    memorizedCanSwitchSpouse = this.canSwitchSpouse
  }

  get customer(): Customer | null {
    return this.$store.getters[AuthTypes.getters.customer]
  }

  get hasSpouse(): boolean {
    return ProfileModuleUtils.getProfileField('hasSpouse')
  }

  get didSwitchSpouse(): boolean {
    return store.state.profile.profile.didSwitchSpouse
  }

  onInput(value: boolean) {
    ProfileModuleUtils.setProfileField('hasSpouse', value)
  }

  switchSpouse() {
    store.commit(ProfileTypes.mutations.switchSpouseProfile)
  }

  @Watch('customer')
  private async onCustomerChanged() {
    this.updateValue()
  }
}
