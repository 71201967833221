
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import { Watch } from 'vue-property-decorator'
import store from '@/store/store'
import { EDUCATION_LEVEL_OPTIONS } from '@/data/point-calculator/categories/core/education-point-calculator'
import FormattingUtils from '@/common/util/formatting-utils'
import PointCategory from '@/data/point-calculator/point-category'

import EducationFormBExplanation from './EducationFormBExplanation.vue'
import CategoryPointsLabel from '../../common/CategoryPointsLabel.vue'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

@Component({
  components: {
    CategoryPointsLabel,
  },
})
export default class EducationFormB extends Vue {
  educationLevels = EDUCATION_LEVEL_OPTIONS

  get educationLevelId(): number | null {
    return ProfileModuleUtils.getSpouseField('education')
  }

  get category(): PointCategory {
    return store.state.profile.points.categoryB.education
  }

  onEducationLevelChanged(value: number | null) {
    ProfileModuleUtils.setSpouseField('education', value)
  }

  showHelp() {
    this.$dialog.show({
      component: EducationFormBExplanation,
      large: true,
    })
  }
}
