
import Vue from 'vue'
import Component from 'vue-class-component'
import PointCalculatorNavigationItem from './point-calculator-navigation-item'
import { Prop } from 'vue-property-decorator'
import categoryColor from '../../common/point-calculator-section-color'

@Component
export default class PointCalculatorNavSection extends Vue {
  @Prop({ required: true })
  item!: PointCalculatorNavigationItem

  sectionHeaderStyle(item: PointCalculatorNavigationItem): any {
    return item.disabled
      ? null
      : {
          backgroundColor: categoryColor(item.section),
        }
  }

  onItemClicked(item: PointCalculatorNavigationItem) {
    this.$emit('item-clicked', item.section)
  }
}
