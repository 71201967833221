
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import SelectOption from '@/common/interfaces/select-option'

import FormattingUtils from '@/common/util/formatting-utils'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

import CategoryDFrenchBonusExplanation from './CategoryDFrenchBonusExplanation.vue'
import PointCalculatorHelpLabel from '../../common/PointCalculatorHelpLabel.vue'
import JobOfferForm from '../../common/JobOfferForm.vue'
import CanadianSiblingSwitch from '../../common/CanadianSiblingSwitch.vue'
import ProvincialNominationSwitch from '../../common/ProvincialNominationSwitch.vue'
import { EducationLevel } from 'shared-entities'

@Component({
  components: {
    PointCalculatorHelpLabel,
    JobOfferForm,
    CanadianSiblingSwitch,
    ProvincialNominationSwitch,
  },
})
export default class CategoryDForm extends Vue {
  canadianEducationDesc = ''

  canadianEducationOptions: SelectOption[] = [
    { id: 0, title: 'label.none' },
    { id: 1, title: 'profile.canadianEducation.oneOrTwoYears' },
    { id: 3, title: 'profile.canadianEducation.threeOrMoreYears' },
  ]

  created() {
    import('./canadian-education.partial').then(html => (this.canadianEducationDesc = html.default))
  }

  get hasPostSecondaryEducation(): boolean {
    const education = ProfileModuleUtils.getProfileField('education')
    return education ? education >= EducationLevel.ONE_YEAR_DEGREE : false
  }

  get canadianEducationOptionId(): number {
    return ProfileModuleUtils.getProfileField('canadianEducation')
  }

  setCanadianEducationOption(optionId: number) {
    const years = Math.max(0, optionId)
    ProfileModuleUtils.setProfileField('canadianEducation', years)
  }

  get frenchBonusPoints(): string {
    return FormattingUtils.formatCategoryPoints(store.state.profile.points.categoryD.frenchBonus)
  }

  showFrenchBonusHelp() {
    this.$dialog.show({
      component: CategoryDFrenchBonusExplanation,
      large: true,
    })
  }
}
