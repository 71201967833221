
import Vue from 'vue'
import Component from 'vue-class-component'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import { CecWorkExpType } from 'shared-entities'
import SelectOption from '@/common/interfaces/select-option'

@Component({
  i18n: {
    messages: {
      en: {
        local: {
          workExperienceSwitch:
            'Do you have at least 1 year of Canadian work experience within the last 3 years in one or more 0, A, B NOC jobs?',
          workExperienceSwitchDescription:
            'Work experience can be either combination of full-time or part-time work.',
        },
      },
    },
  },
})
export default class CecEligibilityForm extends Vue {
  get hasCanadianWorkExp(): boolean {
    return ProfileModuleUtils.getProfileField('workExp') >= 1
  }

  get hasCecWorkExp(): boolean {
    return ProfileModuleUtils.getCecField('hasWorkExp')
  }

  setHasCecWorkExp(value: boolean) {
    ProfileModuleUtils.setCecField('hasWorkExp', value)
  }

  workExpTypeOptions: SelectOption[] = [
    { id: CecWorkExpType.NOC_0_A, title: 'NOC-0/A' },
    { id: CecWorkExpType.NOC_B, title: 'NOC-B' },
  ]

  get workExpType(): number | null {
    return ProfileModuleUtils.getCecField('workExpType')
  }

  setWorkExpType(type: number | null) {
    ProfileModuleUtils.setCecField('workExpType', type)
  }
}
