
import Vue from 'vue'
import Component from 'vue-class-component'

import Program from '@/data/entity/programs/program.entity'
import allPrograms from '@/data/eligibility-calculator/all-programs'
import ProgramId from '@/data/entity/programs/program-id.entity'
import ProgramEligibility from '@/data/entity/programs/program-eligibility.entity'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import store from '@/store/store'
import EligibilityCriterionResult from '@/data/entity/programs/eligibility-criterion-result.entity'
import EligibilityStatus from '@/data/entity/programs/eligibility-status.entity'

import EligibilityProgramSelector from './EligibilityProgramSelector.vue'
import EligibilityCriterionView from './EligibilityCriterionView.vue'

import CecEligibilityForm from './programs/cec/CecEligibilityForm.vue'
import FstEligibilityForm from './programs/fst/FstEligibilityForm.vue'
import FswEligibilityForm from './programs/fsw/FswEligibilityForm.vue'
import PnpEligibilityForm from './programs/pnp/PnpEligibilityForm.vue'
import AppUtils from '@/ui/util/app-utils'
import RouteNames from '@/ui/router/route-names'

const eligibilityForms: { [key in ProgramId]: string | null } = {
  [ProgramId.CEC]: 'CecEligibilityForm',
  [ProgramId.FST]: 'FstEligibilityForm',
  [ProgramId.FSW]: 'FswEligibilityForm',
  [ProgramId.PNP]: 'PnpEligibilityForm',
}

@Component({
  components: {
    EligibilityCriterionView,
    EligibilityProgramSelector,

    CecEligibilityForm,
    FstEligibilityForm,
    FswEligibilityForm,
    PnpEligibilityForm,
  },
})
export default class EligibilitySummary extends Vue {
  selectedProgram: Program = allPrograms[0]

  get programEligibility(): ProgramEligibility | null {
    const eligibility = store.state.profile.eligibility
    return eligibility[this.selectedProgram.id] || null
  }

  get isEligibleForSelectedProgram(): boolean {
    const programEligibility = this.programEligibility
    return programEligibility ? programEligibility.status === EligibilityStatus.ELIGIBLE : false
  }

  get eligibilityStatusMessage(): string {
    if (this.isEligibleForSelectedProgram) {
      return 'You are eligible for this program'
    } else {
      return 'You are not eligible for this program'
    }
  }

  get selectedProgramForm(): string | null {
    return eligibilityForms[this.selectedProgram.id]
  }

  get criteria(): EligibilityCriterionResult[] | null {
    const programEligibility = this.programEligibility
    return programEligibility ? programEligibility.criteria : null
  }

  goToNextStep() {
    this.$router.push({ name: RouteNames.GUIDE_POOL })
  }
}
