
import Vue from 'vue'
import Component from 'vue-class-component'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'

@Component({
  i18n: {
    messages: {
      en: {
        local: {
          canadianSiblingSwitch:
            'Do you or your spouse or common-law partner (if they are coming to Canada with you) have a brother ' +
            'or sister living in Canada who is a citizen or permanent resident of Canada?',
          canadianSiblingDesc:
            '<h4>They must:</h4>' +
            '<ul><li>be 18 years old or older</li>' +
            '<li>be related by blood, marriage, common-law partnership or adoption</li>' +
            '<li>have a parent in common</li></ul>',
        },
      },
    },
  },
})
export default class CanadianSiblingSwitch extends Vue {
  get hasCanadianSibling(): boolean {
    return ProfileModuleUtils.getProfileField('hasCanadianSibling')
  }

  setHasCanadianSibling(value: boolean) {
    ProfileModuleUtils.setProfileField('hasCanadianSibling', value)
  }
}
