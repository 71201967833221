
import Vue from 'vue'
import Component from 'vue-class-component'

import ProvincialNominationSwitch from '../../../common/ProvincialNominationSwitch.vue'

@Component({
  components: {
    ProvincialNominationSwitch,
  },
})
export default class PnpEligibilityForm extends Vue {}
